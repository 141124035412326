import React from "react";
import { useLocation } from "react-router-dom";
import Header from "./components/header/Header";
import { Outlet } from "react-router-dom";
import useUserStore from "./app/user";

function MainLayout() {
  const location = useLocation();
  //const navigate = useNavigate();
  const user = useUserStore((state) => state.user);
  console.log("user", user);

  // Check if the current location is the login page
  const isLoginPage = location.pathname === "/login";

  return (
    <div>
      {user.FullName === "Vusi Shongwe" && (
        <div>
          {!isLoginPage && (
            <div className="header">
              <Header />
            </div>
          )}
          <div>
            <Outlet />
          </div>
        </div>
      )}
      {user.FullName !== "Vusi Shongwe" && (
        <div>
          <h1>We are busy Upgrading Your App</h1>
          <p>
            Please note that PMS will be shutdown from the 31st January 2025
          </p>
          <p>
            after work for about five days, for migration of the current PMS to
          </p>
          <p>
            a more advanced system to assist the organisation this is after the
          </p>
          <p>feedback from staff on the current system.</p>
        </div>
      )}
    </div>
  );
}

export default MainLayout;
